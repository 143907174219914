<template>
    <div class="accounts-select">
        <CustomDropdown ref="dropdown" drop-down-width="600px" un-styled @on-selected="selected" :options="accountsDataComputed" :selected="{ value: preSelectedAccount }" :disabled="disabled" />
    </div>
</template>
<script>
export default {
    props: {
        accountsData: {
            type: Array,
            default: () => [],
        },
        preSelectedAccount: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        CustomDropdown: () => import("../../components/searchClientNew.vue"),
    },

    computed: {
        accountsDataComputed() {
            return this.accountsData.map(item => {
                return {
                    name: item.number + " " + item.name,
                    value: item.number,
                };
            });
        },
    },

    data() {
        return {
            selectedAccount: this.preSelectedAccount || null,
        };
    },

    methods: {
        selected(accountNumber) {
            this.$emit("selected", accountNumber.value);
        },

        selectBlur(status) {
            this.$emit("blur", status);
        },
    },
};
</script>
<style>
.accounts-select .el-select .el-input__inner {
    border: none;
}
</style>
