<template>
    <tr :class="{ 'border-2 border-red-400': warningBorder }">
        <td>
            <!-- <div v-if="!accountExistOnOurSide && preLoadedItem.readOnly" class="bg-gray-50 py-[4px] px-[8px]">
                <p class="text-sm">
                    {{ preLoadedItem.accountName }}
                </p>
            </div> -->
            <AccountsDropdown
                ref="accountsDropdown"
                :accounts-data="accountsData"
                :pre-selected-account="selectedAccount"
                :disabled="preLoadedItem.readOnly"
                @selected="accountSelected"
                @blur="selectBlur"
            />
        </td>
        <td>
            <input @keydown.enter="enterPressed" @keyup="updateValues" v-model="comment" :disabled="preLoadedItem.readOnly" class="w-full text-sm py-1 px-2" />
        </td>
        <td class="w-4">
            <span v-if="preLoadedItem.correction" class="flex items-center justify-center bg-company-blue w-4 h-4 mt-[7px] mx-1 text-xs rounded-full text-white">K</span>
        </td>
        <td class="w-28">
            <InputCurrency
                ref="debitInput"
                v-model="debit"
                custom-class="w-full text-sm py-1 px-2 text-right"
                :disabled="!!credit || preLoadedItem.readOnly"
                :min="0"
                @focus="debitInputFocus = true"
                @blur="debitInputFocus = false"
                @keyPressed="keyPressed"
                @keyUp="updateValues"
            />
        </td>
        <td class="w-28">
            <InputCurrency
                ref="creditInput"
                v-model="credit"
                custom-class="w-full text-sm py-1 px-2 text-right"
                :disabled="!!debit || preLoadedItem.readOnly"
                :min="0"
                @focus="creditInputFocus = true"
                @blur="creditInputFocus = false"
                @keyPressed="keyPressed"
                @keyUp="updateValues"
            />
        </td>
        <!-- <td class="w-20">
            <p class="text-sm py-1 px-2 text-right">
                9999
            </p>
        </td> -->
        <td class="w-12 px-0" align="right">
            <el-button v-show="isAccrualable" :disabled="(!debit && !credit) || !isAccrualable" type="primary" class="p-1 m-[2px] mr-[3px]" @click="showSelectPeriodModal" tabindex="-1">
                <i class="fas fa-calendar" />
            </el-button>
            <el-button v-if="!preLoadedItem.readOnly" type="warning" class="p-1 m-0 mr-[2px]" @click="removeEntry" :disabled="canDelete || preLoadedItem.readOnly" tabindex="-1">
                <i class="fas fa-trash" />
            </el-button>
            <!-- <el-button class="p-1 m-0 mr-[2px]" @click="dialogTableVisible = true" tabindex="-1"> <i class="fa-solid fa-projector" /> </el-button> -->
        </td>
        <el-dialog :visible="dialogTableVisible" title="Projekt" :modal="true" width="25%">
            <p>{{ preLoadedItem.transactionId }}</p>
            <el-form v-for="(link, index) in links" :key="index" class="flex flex-row relative">
                <el-form-item label="Projekt" prop="accountNumber">
                    <Dropdown v-model="link.projectId" :options="getDropdownOptions()" />
                </el-form-item>
                <el-form-item class="ml-3" label="Andel %" prop="accountNumber">
                    <el-input-number v-model="link.ratio" :min="0" :max="100" label="Andel" />
                </el-form-item>
                <el-form-item class="ml-3" label="Summa" prop="accountNumber">
                    <el-input-number v-model="link.amount" :min="0" :max="Math.max(debit, credit)" :label="'Summa'" />
                </el-form-item>
                <el-button class="p-1 m-0 mr-[2px] absolute top-0 right-0" @click="removeProjectLinkRow(index)" type="">
                    <i class="fa-solid fa-xmark text-red-600" />
                </el-button>
            </el-form>
            <div class="flex flex-row">
                <el-button class="p-1 m-0 mr-[2px]" @click="addProjectLinkRow()" type="">
                    <i class="fa-solid fa-plus text-green-400" />
                </el-button>
                <div class="ml-2">Koppla fler projekt</div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogTableVisible = false">Avbryt</el-button>
                    <el-button type="primary" @click="saveProjectLinks()">
                        Spara
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </tr>
</template>
<script>
import AccountsDropdown from "./AccountsDropdown.vue";
import Dropdown from "@/components/dropdown.vue";

export default {
    props: {
        projects: {
            type: Array,
            default: () => [],
        },
        accountsData: {
            type: Array,
            default: () => [],
        },
        debitSummary: {
            type: Number,
            default: 0,
        },
        creditSummary: {
            type: Number,
            default: 0,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        warningBorder: {
            type: Boolean,
            default: false,
        },
        preLoadedItem: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            links: [],
            dialogTableVisible: false,
            projectId: 0,
            ratio: 0,
            amount: 0,
            selectedAccount: this.preLoadedItem.account || null,
            debit: this.preLoadedItem.debit || null,
            credit: this.preLoadedItem.credit || null,
            comment: this.preLoadedItem.text || "",
            debitInputFocus: false,
            creditInputFocus: false,
        };
    },

    components: {
        AccountsDropdown: AccountsDropdown,
        Dropdown,
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
    },

    created() {
        // If user will click Vänd button, do not select dropdown with accounts
        // this.addProjectLinkRow();
        // console.log("Prop projects ", this.projects);
        if (this.preLoadedItem.id !== 999999) return;
        setTimeout(() => {
            // this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
        }, 100);
    },

    computed: {
        isAccrualable() {
            return false; //this.accountsData.find(account => account.number === this.selectedAccount)?.accruable;
        },
    },

    methods: {
        saveProjectLinks() {
            this.$emit("saveProjectLinks", this.links);
            console.log("emit dim 1");
            this.dialogTableVisible = false;
        },

        addProjectLinkRow() {
            this.links.push({ projectId: 6, ratio: 0, amount: 0, transactionId: this.preLoadedItem.transactionId });
        },

        removeProjectLinkRow(index) {
            this.links.splice(index, 1);
        },

        getDropdownOptions() {
            let options = [];
            this.projects.forEach(project => {
                let object = { value: project.id, name: project.name };
                options.push(object);
            });
            return options;
        },
        updateValues() {
            this.$emit("updateValues", {
                account: this.selectedAccount,
                debit: this.debit || 0,
                credit: this.credit || 0,
                comment: this.comment,
            });
        },

        selectBlur(status) {
            if (!status && !this.selectedAccount && (this.credit || this.debit)) {
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.blur();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
            }
        },

        accountSelected(account) {
            this.selectedAccount = account;
            this.updateValues();
            if (this.debit || this.credit) {
                this.approve();
                return;
            }

            if (!account) return;

            setTimeout(() => {
                this.$refs.debitInput.$refs.inputRef.focus();
            }, 100);
        },

        approve() {
            this.$emit("approve", {
                id: Math.random(),
                account: this.selectedAccount,
                debit: this.debit || 0,
                credit: this.credit || 0,
                comment: this.comment,
            });
        },

        keyPressed(e) {
            if (e.key !== "Tab" && e.key !== "Enter") {
                return;
            }

            if (e.key === "Enter" && !this.credit && !this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            // If everything is empty
            if (e.key === "Tab" && this.creditInputFocus && !this.credit && !this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            // If account is selected but fields are empty
            if (e.key === "Tab" && this.creditInputFocus && !this.credit && !this.debit && this.selectedAccount) {
                e.preventDefault();
                this.$refs.debitInput.$refs.inputRef.focus();
                return;
            }

            if (e.key === "Tab" && this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            if (e.key === "Tab" && this.credit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            if (e.key === "Tab" && this.debit && this.selectedAccount) {
                this.approve();
                return;
            }

            if (e.key === "Tab" && this.credit && this.selectedAccount) {
                this.approve();
                return;
            }

            if (e.key === "Enter" && !this.credit && !this.debit && this.selectedAccount && (this.debitInputFocus || this.creditInputFocus)) {
                if (this.debitSummary > this.creditSummary) {
                    this.credit = (this.debitSummary - this.creditSummary).roundToEven2();
                    this.$refs.creditInput.$refs.inputRef.focus();
                }
                if (this.creditSummary > this.debitSummary) {
                    this.debit = (this.creditSummary - this.debitSummary).roundToEven2();
                    this.$refs.debitInput.$refs.inputRef.focus();
                }
                this.updateValues();
                return;
            }

            if (e.key === "Enter" && (this.credit || this.debit) && this.selectedAccount && (this.debitInputFocus || this.creditInputFocus) && this.debitSummary === this.creditSummary) {
                this.$emit("createVoucher");
                return;
            }
        },

        enterPressed() {
            if ((this.credit || this.debit) && this.selectedAccount && this.debitSummary === this.creditSummary) {
                this.$emit("createVoucher");
                return;
            }
        },

        removeEntry() {
            this.$emit("removeEntry");
        },

        showSelectPeriodModal() {
            this.$emit("showSelectPeriodModal", {
                account: this.selectedAccount,
                amount: this.debit || this.credit,
            });
        },
    },
};
</script>
<style scoped>
.dialog-footer button:first-child {
    margin-right: 10px;
}
td {
    border: 1px solid #ddd;
}
</style>
